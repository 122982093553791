<template>
	<div class="p-3">

		<button class="btn btn-link btn-xs mx-2" @click.prevent="totalReset">RESET</button>

		<stage-viewer
				v-if="lastResult"
				:reels="lastResult.state.reels" :config="computedConfig"
				:wins="computedLastResult.wins"
				:loading="loading" :modified-reels="lastResult.modified_reels"
				:stages="lastResult.stages"
		>
			<div class="py-3" v-if="lastResult">
				<div class="d-flex justify-content-between">
					<div class="d-inline-flex flex-grow-1 align-items-center justify-content-center">
						<h5 class="text-nowrap m-0 mx-2">Spins</h5>
						<div class="text-small">{{ totalSpins | numeral('0,0') }}</div>
					</div>
					<div class="d-inline-flex flex-grow-1 align-items-center justify-content-center">
						<h5 class="text-nowrap m-0 mx-2">Total bet</h5>
						<div class="text-small">{{ totalBet | numeral('0,0') }}</div>
					</div>
					<div class="d-inline-flex flex-grow-1 align-items-center justify-content-center">
						<h5 class="text-nowrap m-0 mx-2">Total win</h5>
						<div class="text-small">{{ totalWin | numeral('0,0') }}</div>
					</div>
					<div class="d-inline-flex flex-grow-1 align-items-center justify-content-center">
						<h5 class="text-nowrap m-0 mx-2">Balance</h5>
						<div class="text-small">{{ lastResult.player.balance | numeral('0,0') }}</div>
					</div>
					<div class="d-inline-flex flex-grow-1 align-items-center justify-content-center">
						<h5 class="text-nowrap m-0 mx-2">RTP</h5>
						<div class="text-small">{{ rtp | numeral('0.00%') }}</div>
					</div>

					<div class="flex-grow-0" v-if="lastResult">
						<div class="ml-2 flex-grow-1 text-white text-center"
								 v-for="bonus in lastResult.bonus">
							<div class="bg-dark p-2">
								<h5 v-text="`Bonus ${bonus.name}`"
										class="text-uppercase text-small text-nowrap"></h5>
								<div class="progress mb-2" style="height: 3px;"
										 v-if="bonus.trigger.counters.length > 1">
									<div class="progress-bar bg-success"
											 :style="{width: `${(bonus.trigger.overall * 100).toFixed()}%`}"></div>
								</div>
								<div v-for="property in bonus.trigger.counters" class="d-block w-100">
									<div class="text-small">
										<div class="text-small" v-text="property.property"></div>
									</div>
									<div class="text-nowrap text-small"
											 v-text="`${property.current} / ${property.threshold}`"></div>
									<div class="progress progress-success">
										<div class="progress-bar"
												 :style="{width: `${(property.current / property.threshold * 100).toFixed()}%`}"></div>
									</div>
									<div class="d-inline-flex">
										<button class="btn btn-sm btn-outline-primary text-small mt-2 mx-1"
														@click="setExtraValue(property.property, property.threshold - 1)">
											Set to
											{{ property.threshold - 1 }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<rng-viewer class="mt-2 p-3 bg-light" v-if="lastResult && lastResult.rng" :rng="lastResult.rng"/>

				<hr>

				<div class="position-relative text-right" v-if="lastResult">
					<div class="d-flex flex-fill mt-3 align-items-center" v-if="!gambleEnabled">
						<form @submit.prevent="doSpin" class="bg-light w-100">
							<div class="d-flex w-100">
								<div class="flex-grow-0 d-flex align-items-center text-center">
									<div>
										<h5>Lines</h5>
										<select class="form-control" v-model="request.lines">
											<option :value="payline" v-for="payline in config.valid_paylines">{{ payline }}
												lines
											</option>
										</select>
									</div>
								</div>
								<div class="flex-fill"></div>
								<div>
									<div class="d-flex flex-grow-1 align-items-center flex-column">
										<template v-if="['spin', 'freespin'].includes(lastResult.next_action.type)">
											<div>
												<input class="form-check-input mr-2" id="useReelStops" type="checkbox"
															 v-model="useReelStops"
															 value="0">
												<label for="useReelStops" class="form-check-label text-small text-light">Use
													reel
													stops</label>
											</div>
											<div v-if="useReelStops">
												<input class="form-control py-0" type="text"
															 v-model="reelStops">
											</div>
										</template>

										<template v-if="lastResult.next_action.type === 'bonus'">
											<div>
												<input class="form-check-input mr-2" id="useBonusRng" type="checkbox"
															 v-model="useBonusRng"
															 value="0">
												<label for="useBonusRng" class="form-check-label text-small text-light">Use
													bonus RNG</label>
											</div>
											<div v-if="useBonusRng">
												<input class="form-control p-0" type="number" step="1" min="0"
															 v-model="bonusRng">
											</div>
										</template>

										<button class="btn btn-link flex-grow-1 text-success"
														@click.prevent="checkCombination"
														:disabled="!['spin', 'freespin'].includes(lastResult.next_action.type)">CHECK
											COMBINATION
										</button>
									</div>
									<div class="btn-group w-100">
										<button ref="spinButton" :disabled="!spinReady"
														class="btn btn-primary flex-grow-1 py-3 text-uppercase d-block w-100"
														@click="doSpin" v-if="lastResult">{{ lastResult.next_action.type }}
										</button>
									</div>
									<div class="text-small text-muted bg-light p-2 mt-2">

										<div class="bg-success text-white p-2" v-if="lastResult.state.freespins.currentMultiplier > 1">
											<strong>BONUS MULTIPLIER</strong>:
											<strong>X{{ lastResult.state.freespins.currentMultiplier }}</strong>
										</div>

										<div :class="{'text-light': lastResult.state.freespins.left === 0}">
											<strong>Remaining FS</strong>:
											<strong>{{ lastResult.state.freespins.left }}</strong>
										</div>

										<div v-for="bonus in lastResult.bonus"
												 :class="{'text-light': bonus.current.left === 0}">
											<strong>{{ bonus.name }}</strong> remaining:
											<strong>{{ bonus.current.left }}</strong>
										</div>

										<button class="btn btn-link text-small" @click="spinUntilFreespin">Spin until next freespin
											session
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="text-center flex-grow-1" v-else>
						<gamble-ui @onGambleCollect="collectGamble" @onGamble="doGamble"
											 :gamble-state="lastResult.next_action.gamble"/>
					</div>
				</div>

				<div>
					<input type="checkbox" v-model="request.autoplay" id="autoplay" class="mr-2"/>
					<label for="autoplay">Autoplay</label>
				</div>

				<button class="btn btn-link btn-xs mx-2" @click.prevent="totalReset">RESET</button>

				<hr>

				<div class="row controls mt-3" v-if="lastResult && lastResult.state && lastResult.state.bonus.win > 0">
					<div class="col">
						<div class="bg-info text-white p-3 w-100">
							<div class="text-center d-flex align-items-center justify-content-center">
								<h5>Bonus round</h5>
							</div>
							<div class="row d-flex flex-nowrap">
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Wins</h5>
									<div class="text-small" v-text="lastResult.state.bonus.win"></div>
								</div>
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Current amount</h5>
									<div class="text-small" v-text="lastResult.state.bonus.currentAmount"></div>
								</div>
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Total win amount</h5>
									<div class="text-small" v-text="lastResult.state.bonus.totalAmount"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="buyFeatures.length > 0">

					<h4 class="">This game supports buy feature</h4>

					<div class="d-flex">
						<div v-for="(feature, index) in buyFeatures" class="card">
							<div class="card-header">
								<div class="card-title">#{{ index + 1 }}</div>
								<div class="card-body text-left">
									<div><strong>Id</strong>: {{ feature.id }}</div>
									<div><strong>Feature</strong>: {{ feature.feature }}</div>
									<div><strong>Multiplier</strong>: {{ feature.betMultiplier }}</div>
								</div>
							</div>
							<div class="card-footer p-0">
								<button @click="buyFeature(feature)" class="btn btn-outline-primary d-block w-100">Buy feature
									#{{ index + 1 }}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="row controls mt-3" v-if="lastResult && lastResult.state.freespins.total > 0">
					<div class="col">
						<div class="bg-success text-white p-2 w-100">
							<div class="text-center d-flex align-items-center justify-content-center">
								<h5>Freespins</h5>
							</div>
							<div class="row d-flex flex-nowrap">
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Left</h5>
									<div class="text-small" v-text="lastResult.state.freespins.left"></div>
								</div>
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Total</h5>
									<div class="text-small" v-text="lastResult.state.freespins.total"></div>
								</div>
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Total win amount</h5>
									<div class="text-small" v-text="lastResult.state.freespins.totalAmount"></div>
								</div>
								<div class="col d-inline-flex align-items-center justify-content-end">
									<h5 class="text-nowrap m-0 mx-2">Total Bonus amount</h5>
									<div class="text-small" v-text="lastResult.state.freespins.totalBonusAmount"></div>
								</div>
							</div>
						</div>
					</div>
					s
				</div>
			</div>
		</stage-viewer>

		<div class="mb-2">
			<h5>Server URL</h5>
			<div class="bg-light p-2 border">
				<button class="btn btn-info btn-sm mr-2" v-clipboard="serverPermalink">COPY</button>
				{{ serverPermalink }}
			</div>
		</div>

		<div class="mt-5">
			<h5>Last request</h5>
			<json-viewer class="last-response p-4" :value="lastRequest" :sort="true" :copyable="true" theme="jv-dark"
									 :expand-depth="1"></json-viewer>
		</div>

		<div class="mt-5" v-if="lastResult">
			<h5>Last response</h5>
			<json-viewer class="last-response p-4" :value="lastResult" :sort="true" :copyable="true" theme="jv-dark"
									 :expand-depth="1"></json-viewer>
		</div>
	</div>
</template>
<script>
import api from '@/api'
import StageViewer from "./StageViewer";
import RngViewer from "./RngViewer";
import GambleUi from "./GambleUi";

export default {
	components: {GambleUi, RngViewer, StageViewer},
	props: {
		config: {
			type: Object
		},
		uuid: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			lastRequest: {},
			lastResult: null,
			request: {
				bet: 1,
				lines: 0,
				action: 'spin',
				autoplay: false
			},
			offset: 0,
			totalSpins: 0,
			totalWin: 0,
			totalBet: 0,
			loading: false,
			spinReady: false,
			winningAnimationInterval: null,
			buyFeatures: [],
			useReelStops: false,
			useBuyFeature: null,
			reelStops: "",

			useBonusRng: false,
			bonusRng: 0,
			useMiniSlot: false
		}
	},
	async mounted() {
		this.lastResult = await api.Simulator.initGame(this.uuid)
		this.request.lines = parseInt(this.config.valid_paylines[this.config.valid_paylines.length - 1])

		if (this.lastResult.buyFeatures) {
			this.buyFeatures = this.lastResult.buyFeatures
		}
		this.reset()
	},
	methods: {
		async doSpin() {
			this.loading = true
			this.spinReady = false
			this.totalSpins++

			this.useMiniSlot = (this.lastResult && this.lastResult.next_action.type === "bonus" && this.lastResult.next_action.details.type === "MiniSlotBonus")

			const nextAction = this.lastResult.next_action.type
			this.request.action = nextAction

			let totalBet = this.request.bet * this.request.lines

			this.request.extra = {}

			if (this.useBuyFeature) {
				totalBet = this.request.bet * this.request.lines * this.useBuyFeature.betMultiplier
				this.request.extra = {
					type: "buy_feature",
					total_bet: totalBet,
					id: this.useBuyFeature.id
				}
			}

			if (nextAction === "bonus") {
				this.lastRequest = {
					bonus: this.lastResult.next_action.details.name,
					pick: 0,
					rng: this.useBonusRng ? this.bonusRng : null
				}
				this.lastResult = await api.Simulator.doBonus(this.uuid, this.lastResult.next_action.details.name, this.useBonusRng ? this.bonusRng : null).catch(() => {
					this.loading = false
					this.spinReady = true
				})
			} else {
				this.lastRequest = this.request

				this.lastResult = await api.Simulator.doSpin(this.uuid, this.lastRequest).catch(() => {
					this.loading = false
					this.spinReady = true
				})
			}

			this.useMiniSlot = this.useMiniSlot && this.lastResult.details

			if (nextAction === 'spin') {
				this.totalBet += totalBet
			}

			this.totalWin += this.lastResult.wins.reduce((c, w) => c + (w.winValue), 0)
			this.loading = false

			this.spinReady = true

			if (this.lastResult.rng !== undefined) {
				this.reelStops = Object.values(this.lastResult.rng).join(",")
			}

			this.$nextTick(() => this.latestReels = JSON.stringify(this.lastResult.state.reels))
		},
		async buyFeature(feature) {
			this.useBuyFeature = feature
			await this.doSpin()
			this.useBuyFeature = null
		},
		async spinUntilFreespin() {
			do {
				await this.doSpin()
			}
			while (this.lastResult.next_action.type !== "freespin")
		},
		async checkCombination() {
			this.loading = true

			let params = {
				reels: this.lastResult.state.reels,
				lines: this.request.lines,
				bet: this.request.bet
			}

			if (this.useReelStops) {
				let tmp = {}
				let reels = this.reelStops.split(",")
				for (let index in reels) {
					tmp[`reel_${parseInt(index) + 1}`] = parseInt(reels[index])
				}
				params.reels = tmp
			}

			console.log(params)

			this.lastResult = await api.Simulator.checkCombination(this.uuid, this.useReelStops, params)

			this.loading = false
			this.spinReady = true
			this.$nextTick(() => this.latestReels = JSON.stringify(this.lastResult.state.reels))
		},
		reset() {
			this.totalSpins = 0
			this.totalWin = 0
			this.totalBet = 0

			this.spinReady = true

			this.reelStops = '0,0,0,0,0'
		},
		async totalReset() {
			this.spinReady = false
			this.reset()
			this.lastResult = await api.Simulator.resetTestPersistence(this.uuid)
			this.spinReady = true
		},
		async setExtraValue(extra_key, value) {
			this.lastResult = await api.Simulator.setExtraValue(this.uuid, extra_key, value)
		},
		getPaylineById(payline_id) {
			return this.paylines[payline_id - 1]
		},
		async doGamble(choice) {
			this.lastRequest = {
				bet: this.lastResult.next_action.gamble.bet_amount,
				action: 'gamble',
				...choice
			}
			this.lastResult = await api.Simulator.doGamble(this.uuid, this.lastRequest)
		},
		async collectGamble() {
			this.lastResult = await api.Simulator.doGamble(this.uuid, {
				action: 'gamble_collect'
			})
		},
	},
	computed: {
		balance() {
			return this.totalWin - this.totalBet
		},
		rtp() {
			return this.totalWin / this.totalBet
		},
		computedConfig() {
			let ret = this.config
			if (this.useMiniSlot) {
				let bonus = this.config.bonuses.slice().filter(b => b.type === "mini-slot")[0]

				console.log("Bonus", bonus)

				if (bonus !== undefined) {
					ret = bonus.options.minislotConfig
				}
			}
			return ret
		},
		computedLastResult() {
			if (this.useMiniSlot && this.lastResult.details) {
				return {
					...this.lastResult,
					state: {
						...this.lastResult.state,
						reels: this.lastResult.details.reels
					},
				}
			} else {
				return this.lastResult
			}
		},
		paylines() {
			return this.config.paytable.paylines
		},
		standardReels() {
			return this.config.reels.map(r => r.slice(this.offset, 5))
		},
		serverPermalink() {
			return `${location.origin}/server/${this.uuid}`
		},
		currentWinningName() {
			if (this.selectedWin !== null) {
				return `${this.selectedWin.type}: ${this.selectedWin.subtype}`
			}
		},
		hasGamble() {
			return this.lastResult.next_action.gamble !== undefined
		},
		gambleEnabled() {
			return this.hasGamble && ['gamble', 'gamble_collect'].includes(this.lastResult.next_action.type)
		},
	},
	watch: {
		spinReady(ready) {
			if (ready) {
				this.$nextTick(() => this.$refs.spinButton !== undefined ? this.$refs.spinButton.focus() : null)
			}
		}
	},
}
</script>

<style scoped lang="scss">
.loading {
	opacity: 0.3;
}

.gamble-0 {
	background: red;
	border-color: red;
}

.gamble-1 {
	background: #2a282a;
	border-color: #2a282a;
}

.gamble-2 {
	background: #242288;
	border-color: #242288;
}

.gamble-3 {
	background: #598c42;
	border-color: #598c42;
}

.gamble-area button:disabled {
	background-color: #c7c7c7;
	border-color: gray;
	cursor: not-allowed;
}
</style>
