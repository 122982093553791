<template>
	<div class="my-2 gamble-area p-3 text-left">

		<div class="flex-grow-1 d-flex" v-if="gambleState.enabled">
			<gamble-options class="flex-grow-1 bg-light border mx-1 flex-fill w-50" v-bind:key="gambleType.id" :gamble-choice="gambleType"
											v-for="gambleType in gambleChoices" @onGamble="onGamble"/>
		</div>

		<div class="my-2">
			<input type="checkbox" id="force-win" v-model="forceWin"/> <label for="force-win">Force win</label>
		</div>

		<div class="p-3 text-center">
			<button class="btn btn-success px-5 py-2"
							@click="$emit('onGambleCollect')">
				COLLECT {{ gambleState.bet_amount }}
			</button>
		</div>

		<div class="row p-3">
			<div class="col text-center">
				<div class="font-weight-bold">Level</div>
				<div>{{ gambleState.current_level }}</div>
			</div>
			<div class="col text-center">
				<div class="font-weight-bold">Total bet amount</div>
				<div>{{ gambleState.bet_amount }}</div>
			</div>
			<div class="col text-center">
				<div class="font-weight-bold">Total win amount</div>
				<div>{{ gambleState.total_win }}</div>
			</div>
		</div>
	</div>
</template>
<script>
import GambleOptions from "./GambleOptions";

export default {
	name: 'gamble-ui',
	components: {GambleOptions},
	props: {
		gambleState: {}
	},
	data: () => ({
		forceWin: false
	}),
	methods: {
		onGamble(selection) {
			this.$emit('onGamble', {
				...selection,
				rng: this.forceWin ? selection.choice : null
			})
		},
		getChoicesExpanded(multiplier) {
			let available_choices = []

			switch (multiplier) {
				case 2:
					available_choices = [{id: 0, name: 'RED', "class": 'btn-danger'}, {id: 1, name: 'BLACK', "class": 'btn-dark'}]
					break;
				case 4:
					available_choices = [
						{id: 0, name: '♥', "class": 'btn-danger'},
						{id: 2, name: '♣', "class": 'btn-dark'},
						{id: 1, name: '♦', "class": 'btn-danger'},
						{id: 3, name: '♠', "class": 'btn-dark'},
					]
					break;
			}

			const prize = (this.gambleState.prizes.filter(p => p.multiplier === multiplier)[0])

			return {
				multiplier,
				options: available_choices,
				prize: {
					bet: this.gambleState.bet_amount,
					win: prize.prize,
				}
			}
		}
	},
	computed: {
		gambleChoices() {
			return this.gambleState.choices.map(this.getChoicesExpanded)
		}
	}
}
</script>
<style scoped lang="scss">

.gamble-area button:disabled {
	background-color: #c7c7c7;
	border-color: gray;
	cursor: not-allowed;
}
</style>
