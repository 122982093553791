<template>
	<div class="p-2">
		<div class="font-weight-bold">GAMBLE X{{ gambleChoice.multiplier }}</div>
		<div class="d-flex">
			<button class="btn flex-wrap flex-grow-1 py-3" @click="execGamble(option.id)" :class="option.class"
							v-for="option in gambleChoice.options">{{ option.name }}
			</button>
		</div>
		<div class="p-2 text-center text-small">Bet {{ gambleChoice.prize.bet }} Win {{ gambleChoice.prize.win }}</div>
	</div>
</template>
<script>
export default {
	name: 'gamble-options',
	props: {
		gambleChoice: {},
	},
	methods: {
		execGamble(choice) {
			this.$emit('onGamble', {
				choice,
				multiplier: this.gambleChoice.multiplier
			})
		}
	}
}
</script>
<style scoped lang="scss">

.gamble-area button:disabled {
	background-color: #c7c7c7;
	border-color: gray;
	cursor: not-allowed;
}
</style>
