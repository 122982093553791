<template>
    <div class="position-relative">
		<div class="position-absolute top-right">
			<button class="btn btn-outline-primary btn-xs" v-clipboard="() => Object.values(rng).join(',')">COPY</button>
		</div>

		<h5>RNG</h5>
		<div class="d-flex">
			<div v-for="(rng, index) in rng" class="flex-grow-1 w-100 text-small">
				<strong>{{index}}: </strong>
				<code v-text="rng"></code>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        name: 'rng-viewer',
        props: {
            rng: {}
        }
    }
</script>
<style scoped lang="scss">

    .gamble-area button:disabled {
        background-color: #c7c7c7;
        border-color: gray;
        cursor: not-allowed;
    }
</style>
